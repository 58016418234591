import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { getClientsSuccess, getDentistsSuccess, updateChatBotStatusSuccess } from "./actions";
import { getClientsByDentistId, updateChatBotStatusAPI } from "../../api/dentist";
import { cerateConversationAPI, getDentists } from "../../api/client";
import { getCurrentUser, logDebug, userRoleDentist } from "../../helpers/utils";

import { GET_CLIENTS, GET_DENTISTS, UPDATE_CHATBOT_STATUS } from "../actions";

import { IUser } from "../../models/user";
import { UserRole } from "../../constants/user-role";

/**
 * 
 */
export function* watchGetDentists() {
  yield takeEvery(GET_DENTISTS, getDentistsFromAPI);
}

const getDentistsAsync = async () => {
  const user: IUser | null = getCurrentUser();

  if (user?.role === UserRole.CLIENT) {
    console.log('UserRole.CLIENT');
    return await getDentists(user!.email);
  }
  else if (userRoleDentist(user!.role)) {
    console.log('UserRole.DENTIST');
    return await getClientsByDentistId(user!.email);
  }
};

function* getDentistsFromAPI(): any {
  try {
    const response = yield call(getDentistsAsync);
    const dentists = response.data
      .filter((d: any) => d.name)
      .map((p: any) => ({
        id: p.id,
        name: p.name,
        email: p.email,
        image: p.picture,
        dateTime: p.dateTime,
        messageCount: p.messageCount,
        status:p.status,
        dentistId: p?.dentistId
      }));

    yield put(getDentistsSuccess(dentists));
  } catch (error) {
    logDebug(">>> Fetching dentist list failed", null);
  }
}

/**
 * 
 */
export function* watchGetClients() {
  yield takeEvery(GET_CLIENTS, getClientsFromAPI);
}

function* getClientsFromAPI(): any {
  try {
    const response = yield call(getClientsAsync);
    const clients = response.data.map((p: any) => ({
      id: p.id,
      name: p.name,
      email: p.email,
      image: p.picture,
      dateTime: p.dateTime,
      messageCount: p.messageCount,
      isBotEnabled: p.isBotEnabled,
      isBotManuallyEnabled: p.isBotManuallyEnabled,
      whatsAppNumber: p.whatsAppNumber,
      isWhatsApp: p.isWhatsApp
    }));
    console.log('clients', clients)
    yield put(getClientsSuccess(clients));
  } catch (error) {
    logDebug(">>> Fetching client list failed", error);
  }
}

const getClientsAsync = async () => {
  const user: IUser | null = getCurrentUser();

  return await getClientsByDentistId(user!.email);
};

// update chatBot status

export function* watchUpdateChatBotStatus() {
  yield takeEvery(UPDATE_CHATBOT_STATUS, updateChatBotStatusData);
};

const updateChatBotStatusAsync = async (data: any) => {
  return await updateChatBotStatusAPI(data);
};

function* updateChatBotStatusData(action: any): any {
  try {
    const res = yield call(updateChatBotStatusAsync, action.payload);
    yield put(updateChatBotStatusSuccess(res.data));
  } catch (error) {
    logDebug(">>> update ChatBot status Failed", null);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetDentists),
    fork(watchGetClients),
    fork(watchUpdateChatBotStatus),
  ]);
}
